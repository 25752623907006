<template>
  <v-dialog
    v-model="isOpen"
    :max-width="modalDimensions.width"
    persistent
  >
    <v-card>
      <v-card-title class="justify-center">
        <BaseImageRotator
          :imgSrc="getDocumentUrl"
          :rotate="rotate"
          :reset="resetRotation"
          @dimensionChange="dimensionChange"
        />
      </v-card-title>
      <v-card-actions>
        <BaseButton @clicked="rotateImage('left')" icon="rotate_left" />
        <BaseButton @clicked="rotateImage('right')" icon="rotate_right" />
        <v-spacer />
        <BaseButton @clicked="closed" color="accent" text="Close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ues from '@/services/UserEnrollmentService';

export default {
  name: 'BiometricImageDialog',
  props: {
    open: Boolean,
    document: Object,
    authToken: String,
  },
  data: () => ({
    isOpen: false,
    rotate: null,
    resetRotation: false,
    enrollmentUrl: '',
    showEnrollment: false,
    modalDimensions: {
      width: 400,
    },
  }),
  computed: {
    getDocumentUrl() {
      if (this.authToken) {
        return ues.getGuidSrc(this.document.guid, this.authToken);
      }
      return '';
    },
  },
  components: {
    BaseImageRotator: () => import('@/components/base/BaseImageRotator'),
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  watch: {
    open(val) {
      this.isOpen = val;
    },
  },
  methods: {
    closed() {
      this.isOpen = false;
      this.resetRotation = !this.resetRotation;
      this.$emit('closed');
    },
    rotateImage(dir) {
      if (dir === 'left') {
        this.rotate -= 1;
      } else {
        this.rotate += 1;
      }
    },
    dimensionChange(dimensions) {
      this.modalDimensions = dimensions;
    },
  },
  mounted() {
    if (this.open) {
      this.isOpen = this.open;
    }
  },
};
</script>
